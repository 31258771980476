/** @jsx jsx */
import { jsx, Flex, Styled } from 'theme-ui';
import lottie, { AnimationItem } from 'lottie-web';
import {
  createRef, RefObject, useEffect, useState,
} from 'react';
import _ from 'lodash';
import diagramAnimation from 'assets/animations/diagram.json';
import Svg from 'components/svg';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';

const StyledAnimation = styled.div`
  width: calc(100% + 100px);
  margin-left: -30px;
  margin-right: -10px;
  z-index: -1;
`;

const Fade = styled(Styled.div)`
  @media (min-width: 1420px) {
    &:after,  
    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      top: -10px;
      width: 14%;
      height: 100%;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
    &:after {
      left: auto;
      right: 0;
      background: rgb(255,255,255);
      background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    }
  }
`;


const Diagram = () => {
  const animElement: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  const [animObj, setAnimObj] = useState<AnimationItem>();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (_.isNull(animElement.current)) {
      return;
    }
    const animation = lottie.loadAnimation({
      container: animElement.current,
      animationData: diagramAnimation,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    });
    animation.setSpeed(1.8);
    setAnimObj(animation);
    return () => {
      animation.stop();
    };
  }, []);

  useEffect(() => {
    if (!animObj) {
      return;
    }

    if (!isVisible) {
      animObj.stop();
    } else {
      animObj.play();
    }
    return () => {
      animObj.stop();
    };
  }, [isVisible]);

  return (
    <div
      sx={{
        position: 'absolute',
        width: '100%',
        pb: '700px',
        mb: '-700px',
        top: ['-25%', '-25%', '-10%', '-20%', '-20%', '-20%', '-20%'],
        left: 0,
        overflowX: 'hidden',
      }}
    >
      <Styled.div
        sx={{
          position: 'absolute',
          left: '50%',
          top: 0,
          width: '100%',
          maxWidth: '1420px',
          minWidth: '1000px',
          zIndex: -1,
          transform: 'translate3d(-50%,0,0)',
        }}
      >
        <Fade />
        <VisibilitySensor
          onChange={setIsVisible}
          partialVisibility
        >
          <Flex
            sx={{
              minHeight: '240px',
              justifyContent: 'center',
            }}
          >
            <StyledAnimation className="animation-container" ref={animElement} />
            <Svg type="diagramSwoosh" />
          </Flex>
        </VisibilitySensor>
      </Styled.div>
    </div>
  );
};

export default Diagram;
