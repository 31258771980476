/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Media } from 'utils/media';
import DesktopMenu from './desktop-menu';
import MobileMenu from './mobile-menu';

export interface Props {
  startPage: boolean;
  openMenu?: boolean;
}

const Header = () => (
  <React.Fragment>
    <Media greaterThanOrEqual="sm">
      <DesktopMenu />
    </Media>
    <Media lessThan="sm">
      <MobileMenu />
    </Media>
  </React.Fragment>
);

export default Header;
