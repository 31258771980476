import React, { ReactNode } from 'react';

import { Container } from 'theme-ui';
import Header from 'components/layout/header';
import Footer from 'components/layout/footer';
import SEO from '../seo';


interface Props {
  title: string;
  seoDescription?: string;
  startPage?: boolean;
  children?: ReactNode;
}

const Layout = ({
  title,
  seoDescription,
  startPage = false,
  children,
}: Props) => (
  <>
    <SEO
      title={title}
      description={seoDescription}
    />
    <Header />
    <Container as="main" sx={{ overflow: 'visible' }}>
      {children}
    </Container>
    <Footer startPage={startPage} />
  </>
);

export default Layout;
