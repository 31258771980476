/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';


const NavLink = (props: any) => (
  <Link
    {...props}
    activeClassName="active"
    sx={{
      variant: 'links.nav',
      ml: [0, 0, 4],
      fontSize: [4, 5, 3, 3, 3],
    }}
  />
);

export default NavLink;
