/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import Svg from 'components/svg';
import { Link } from 'gatsby';
import BurgerMenu from '../button/burgerMenu';


const MobileMenu = () => (
  <Container
    as="header"
    sx={{
      pt: 4,
      mb: 7,
    }}
  >
    <Link
      to="/"
    >
      <Svg
        type="logoSolid"
        fill="#fff"
        sx={{
          width: ['100px', '120px'],
        }}
      />
    </Link>
    <Svg
      type="topSwooshMobile"
      sx={{
        margin: 0,
        position: 'absolute',
        right: ['auto', 'auto', 0],
        left: [0, 0, 'auto'],
        top: 0,
        zIndex: -1,
      }}
    />
    <BurgerMenu />
  </Container>
);

export default MobileMenu;
