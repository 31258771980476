
export const encode = (data: any) => {
  const formData = new FormData();

  Object
    .keys(data)
    // eslint-disable-next-line array-callback-return
    .map((key) => {
      formData.append(key, data[key]);
    });

  return formData;
};
