/** @jsx jsx */
import {
  jsx, Button, SxProps, Flex,
} from 'theme-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


interface LinkProps {
  iconPosition?: 'right' | 'left';
  icon?: IconProp;
  children?: ReactNode;
}
interface ButtonProps {
  iconPosition?: 'right' | 'left';
  icon?: IconProp;
  children?: ReactNode;
}

export const PrimaryButton = ({
  iconPosition = 'left',
  icon,
  children,
  ...props
}: ButtonProps & SxProps & any) => (
  <Flex
    as={Button}
    {...props}
  >
    {iconPosition === 'left'
    && icon
    && <FontAwesomeIcon icon={icon} sx={{ mr: 3 }} />}
    <span>{children}</span>
    {iconPosition === 'right'
    && icon
    && <FontAwesomeIcon icon={icon} sx={{ ml: 3 }} />}
  </Flex>
);

export const SecondaryButton = ({
  iconPosition = 'left',
  icon,
  children,
  ...props
}: ButtonProps & SxProps & any) => (
  <Button
    {...props}
    variant="secondary"
  >
    {iconPosition === 'left'
    && icon
    && <FontAwesomeIcon icon={icon} sx={{ mr: 3 }} />}
    {children}
    {iconPosition === 'right'
    && icon
    && <FontAwesomeIcon icon={icon} sx={{ ml: 3 }} />}
  </Button>
);

export const IconButton = ({
  iconPosition = 'left',
  icon,
  children,
  ...props
}: LinkProps & SxProps & any) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className="icon-button"
    {...props}
  >
    {iconPosition === 'left'
    && icon
    && <FontAwesomeIcon icon={icon} sx={{ mr: 2 }} />}
    {children}
    {iconPosition === 'right'
    && icon
    && <FontAwesomeIcon icon={icon} sx={{ ml: 2 }} />}
  </button>
);
