/** @jsx jsx */
import {
  jsx, Flex, Heading, Box,
} from 'theme-ui';
import { useState } from 'react';
import StartPageContent from 'content/pages/startpage.yaml';
import { Header4 } from 'components/primitives/text';

import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  answer: string;
  children: string;
}


const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    zIndex: 99999,
    transition: 'all 500ms ease-in-out',
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    overflow: 'hidden',
    height: '80%',
    transform: 'translate3d(-50%, -50%, 0)',
    minWidth: '550px',
    maxWidth: '50%',
    padding: '0',
    borderRadius: '10px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 20px 140px -50px',
  },
};


const Question = ({
  answer,
  children,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Header4
        sx={{
          color: 'secondary',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        onClick={toggleModal}
      >
        {children}
      </Header4>
      <Modal
        isOpen={showModal}
        onRequestClose={toggleModal}
        style={modalStyle as any}
        ariaHideApp={false}
      >
        <button
          type="button"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            mr: 4,
            mt: 3,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: 4,
          }}
          onClick={toggleModal}
        >
          <FontAwesomeIcon icon="times" />
        </button>
        <div sx={{
          overflow: 'auto',
          position: 'relative',
          height: '100%',
          width: '100%',
          pr: '17px',
          boxSizing: 'content-box',
        }}
        >
          <ReactMarkdown sx={{ px: 6, py: 6 }} source={answer} />
        </div>
      </Modal>
    </Box>
  );
};


const CommonQuestions = () => {
  const { commonQuestions, commonQuestionsTitle } = StartPageContent;

  if (commonQuestions.length <= 0) {
    return null;
  }

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        mt: [0, 0, 7, 0, 4, 5],
        mb: [7, 7, 0, 7, 7],
        width: '100%',
      }}
    >
      <Heading as="h3" sx={{ mb: 6 }}>{commonQuestionsTitle}</Heading>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {commonQuestions.map(({ question, answer }: any) => (
          <Question key={question} answer={answer}>{question}</Question>
        ))}
      </Flex>
    </Flex>
  );
};


export default CommonQuestions;
