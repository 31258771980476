/** @jsx jsx */
import { jsx, Container, Flex } from 'theme-ui';
import NavLink from 'components/navLink';
import Svg from 'components/svg';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';


const DesktopMenu = () => {
  const { pathname } = useLocation();

  return (
    <Container
      as="header"
      sx={{
        mt: [0, 0, 6],
        mb: [0, 0, 8],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Link to="/">
        <Svg
          type="logo"
          sx={{
            width: [
              '0px',
              '0px',
              '110px',
              '130px',
              '150px',
              '150px',
              '170px',
            ],
          }}
        />
      </Link>
      <Flex
        sx={{
          justifyContent: 'flex-end',
          flexGrow: 1,
        }}
      >
        <NavLink
          to="/"
        >
          Hem
        </NavLink>
        <NavLink
          to="/om-oss"
        >
          Om oss
        </NavLink>
        <NavLink
          to="/karriar"
        >
          Karriär
        </NavLink>
        <NavLink
          to="/kontakt"
        >
          Kontakt
        </NavLink>
        {/* <NavLink
          to="/friends"
        >
          Friends
        </NavLink> */}
        {/* <NavLink
          to="/blogg"
        >
          Blogg
        </NavLink> */}
      </Flex>
    </Container>
  );
};

export default DesktopMenu;
