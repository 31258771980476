import React from 'react';
import { Input, SxProps } from 'theme-ui';


interface Props {
  type?: 'dark' | 'light';
  placeholder?: string;
  register?: any;
}

export const PrimaryInput = ({
  type = 'dark',
  register,
  ...props
}: Props & SxProps) => (
  <Input
    sx={{
      ...props?.sx,
      borderColor: type === 'dark' ? 'text' : 'white',
      color: type === 'dark' ? 'text' : 'white',
    }}
    ref={register}
    {...props}
  />
);


export const SecondaryInput = ({
  type = 'dark',
  register,
  ...props
}: Props & SxProps & any) => (
  <Input
    sx={{
      ...props?.sx,
      borderWidth: '0',
      borderRadius: '0',
      borderBottomWidth: '1px',
      borderColor: type === 'dark' ? 'text' : 'white',
      color: type === 'dark' ? 'text' : 'white',
      '&:focus': {
        borderColor: 'secondary',
        boxShadow: 'none',
      },
    }}
    ref={register}
    {...props}
  />
);
