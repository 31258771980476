/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, Flex } from 'theme-ui';
import { SecondaryInput } from 'components/layout/input';
import { Media } from 'utils/media';
import { Header2, Paragraph } from 'components/primitives/text';
import { IconButton } from 'components/layout/button';
import FooterContent from 'content/meta/footerForm.yaml';
import { encode } from 'utils/helper';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import MathSigns from 'components/layout/mathSigns';


interface FormInputs {
  'form-name': string;
  email: string;
}
const schema = yup.object().shape({
  email: yup.string().email('Ogiltig epostadress').required('Obligatoriskt'),
});

const ThankYou = () => (
  <Flex
    sx={{
      pt: 2,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Header2>
      Skickat! <span role="img" aria-label="thank-you">🙏</span>
    </Header2>
  </Flex>
);


const FooterForm = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = (data: FormInputs) => {
    fetch('/', {
      method: 'POST',
      body: encode(data),
    })
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };


  return (
    <Flex
      sx={{
        flexDirection: 'column',
        color: ['white', 'white', 'text'],
        maxWidth: ['280px', '280px', '500px'],
        m: ['0 auto'],
        backgroundColor: ['transparent', 'transparent', 'white'],
        borderRadius: ['none', 'none', 'default'],
        mb: [0, 0, 0, 5],
        mt: [5, 8, 7, 0],
        p: [0, 0, 5],
        position: 'relative',
        zIndex: 0,
        overflow: 'hidden',
        boxShadow: ['none', 'none', 'frameShadow'],
      }}
    >
      <div
        sx={{
          zIndex: 1,
          position: 'relative',
        }}
      >
        <Header2>{FooterContent.title}</Header2>
        <Paragraph>{FooterContent.ingress}</Paragraph>
      </div>
      {
      isSubmitted
        ? <ThankYou />
        : (
          <form
            name="Kontaktformulär (footer)"
            method="post"
            action="/"
            netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              zIndex: 1,
              position: 'relative',
            }}
          >
            <p
              sx={{
                visibility: 'hidden',
                lineHeight: 0,
                margin: 0,
                height: 0,
              }}
            >
              <label htmlFor="bot-field">Don’t fill this out if you&apos;re human: <input name="bot-field" /></label>
            </p>
            <noscript>
              <p>This form won’t work with Javascript disabled</p>
            </noscript>
            <input ref={register} type="hidden" name="form-name" value="Kontaktformulär (footer)" />
            <Media lessThan="sm">
              <div
                sx={{
                  width: '100%',
                }}
              >
                <SecondaryInput
                  type="light"
                  placeholder="e-postadress"
                  name="email"
                  register={register}
                  sx={{
                    borderColor: errors.email ? 'danger' : '',
                  }}
                />
                <p className="error-message" sx={{ position: 'absolute', mb: 4 }}>{errors.email?.message}</p>
              </div>
              <Flex
                sx={{
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  icon="paper-plane"
                  type="submit"
                  sx={{
                    mt: 3,
                    whiteSpace: 'nowrap',
                    color: 'white',
                    backgroundColor: 'transparent',
                    appearance: 'none',
                    border: 'none',
                  }}
                >
                  Skicka
                </IconButton>
              </Flex>
            </Media>
            <Media greaterThanOrEqual="sm">
              <Flex
                sx={{
                  alignItems: 'center',
                  mt: 3,
                }}
              >
                <div
                  sx={{
                    width: '100%',
                  }}
                >
                  <SecondaryInput
                    placeholder="e-postadress"
                    name="email"
                    register={register}
                    sx={{
                      borderColor: errors.email ? 'danger' : '',
                    }}
                  />
                  <p className="error-message" sx={{ position: 'absolute', mt: 1 }}>{errors.email?.message}</p>
                </div>
                <IconButton
                  icon="paper-plane"
                  type="submit"
                  sx={{
                    color: 'primary',
                    ml: 4,
                    whiteSpace: 'nowrap',
                    appearance: 'none',
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: 'transparent',
                  }}
                >
                  {FooterContent.buttonText}
                </IconButton>
              </Flex>
            </Media>
          </form>
        )
      }
    </Flex>
  );
};

export default FooterForm;
