/** @jsx jsx */
import {
  jsx, Styled,
} from 'theme-ui';
import Svg from 'components/svg';
import styled from '@emotion/styled';

const FloatingDiv = styled.div`
  display: inline-block;
`;

export const Header1 = Styled.h1;
export const Header2 = Styled.h2;
export const Header3 = Styled.h3;
export const Header4 = Styled.h4;
export const Header5 = Styled.h5;
export const Header6 = Styled.h6;
export const Paragraph = Styled.p;

interface HighlightProps {
  children: string;
}
export const HighlightText = ({
  children,
}: HighlightProps) => {
  if (!children) {
    return null;
  }
  let parts: any = children.split(/(\{{2}.*?\}{2})/gm);

  parts = parts.map((part: string, index: number) => {
    const match = /\{{2}(.*?)\}{2}/gm.exec(part);
    if (match) {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <FloatingDiv key={`hl-${index}`} sx={{ position: 'relative' }}>
          <Svg type="highlightSwoosh" />
          {match[1]}
        </FloatingDiv>
      );
    }

    return (
      <FloatingDiv
        // eslint-disable-next-line react/no-array-index-key
        key={`hl-${index}`}
        sx={{
          mr: / $/.test(part) ? '6px' : 0,
          ml: /^ /.test(part) ? '6px' : 0,
        }}
      >
        {part}
      </FloatingDiv>
    );
  });
  return (
    parts
  );
};
