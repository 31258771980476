/** @jsx jsx */
import React, { useState } from 'react';
import { stack as Menu } from 'react-burger-menu';
import NavLink from 'components/navLink';
import {
  jsx, useThemeUI, Styled, Flex, Box,
} from 'theme-ui';
import classNames from 'classnames';
import Svg from 'components/svg';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Media } from 'utils/media';
import styled from '@emotion/styled';

const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

interface Props {
  open?: boolean;
}

const BurgerMenu = ({
  open = false,
}: Props) => {
  const { theme }: any = useThemeUI();
  const [isOpen, setIsOpen] = useState(open);
  const toggleOpen = (opened: boolean) => {
    if (opened) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    setIsOpen(opened);
  };
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            address
            phone
            email
          }
        }
      }
    `,
  );


  return (
    <React.Fragment>
      <Styled.a
        as="button"
        sx={{
          position: 'relative',
          zIndex: 99999,
        }}
        className="burger-menu-wrapper"
        onClick={() => toggleOpen(!isOpen)}
      >
        <div
          className={classNames([{ open: isOpen }, 'burger-menu'])}
        />
      </Styled.a>
      <MenuWrapper>
        <Menu
          isOpen={isOpen}
          onStateChange={(state) => toggleOpen(state.isOpen)}
          itemListClassName={classNames({ open: isOpen })}
          className={classNames({ open: isOpen })}
          right
          disableAutoFocus
          noTransition
        >
          <NavLink
            to="/"
          >
            Hem
          </NavLink>
          <NavLink
            to="/om-oss"
          >
            Om oss
          </NavLink>
          <NavLink
            to="/karriar"
          >
            Karriär
          </NavLink>
          <NavLink
            to="/kontakt"
          >
            Kontakt
          </NavLink>
          {/* <NavLink
            to="/friends"
          >
            Friends
          </NavLink>
          <NavLink
            to="/blogg"
          >
            Blogg
          </NavLink> */}
          <Flex
            sx={{
              position: 'absolute',
              bottom: 3,
              left: 0,
              width: '100%',
              textAlign: 'center',
              fontSize: 0,
              color: 'muted',
              p: 3,
            }}
          >
            <Link
              to="/"
            >
              <Svg
                type="solidRoundLogo"
                fill={theme.colors?.muted}
                sx={{
                  width: ['40px'],
                }}
              />
            </Link>
            <Box sx={{ mt: 2, px: 4, mb: 2 }}>
              {siteMetadata.address}
            </Box>
            <Box>
              {siteMetadata.phone}
            </Box>
            <Box>
              {siteMetadata.email}
            </Box>
          </Flex>
        </Menu>
      </MenuWrapper>
    </React.Fragment>
  );
};

export default BurgerMenu;
