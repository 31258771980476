/** @jsx jsx */
import Svg from 'components/svg';
import {
  jsx, Grid, Flex,
} from 'theme-ui';
import { Link } from 'gatsby';
import { Media } from 'utils/media';
import FooterForm from 'components/forms/footerForm';
import Diagram from 'components/diagram';
import SiteMeta from 'content/meta/companyInfo.yaml';
import CommonQuestions from '../commonQuestions';


const Footer = () => (
  <Flex
    as="footer"
    sx={{
      position: 'relative',
      variant: 'styles.footer',
      overflow: ['hidden', 'hidden', 'visible'],
      minHeight: ['500px'],
      width: ['100%', '100%', 'auto'],
      bottom: [0],
      mt: [0, 0, 6, 7, 7, 7],
      pt: [7, 7, 6, 6, 6, 7],
      justifyContent: 'center',
      alignContent: 'stretch',
    }}
  >
    <Media greaterThanOrEqual="sm">
      <Diagram />
    </Media>
    <Flex
      sx={{
        variant: 'layout.container',
        zIndex: 0,
        justifyContent: 'flex-end',
        flexDirection: 'column',
        overflow: 'visible',
      }}
    >
      <Media greaterThanOrEqual="sm">
        <CommonQuestions />
      </Media>
      <FooterForm />
      <Media lessThan="sm">
        <Svg type="footerSwooshMobile" />
      </Media>
      <Grid
        columns={[1, 1, '3fr 2fr 2fr 2fr', '3fr 2fr 3fr 3fr']}
        className="footer-meta"
        sx={{
          alignItems: 'center',
          mt: [6, 6, 7, 8],
          mb: [3, 3, 5, 5, 6],
          color: ['lightBlue', 'lightBlue', 'muted'],
          fontSize: [1],
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          <Svg
            type="solidRoundLogo"
            sx={{
              width: ['40px'],
              mr: [0, 0, 3],
              mb: [3, 3, 0],
              fill: ['lightBlue', 'lightBlue', 'muted'],
            }}
          />
          <span sx={{ fontFamily: 'heading', m: ['0 auto'], textAlign: ['center', 'center', 'left'] }}>{SiteMeta.companyName}</span>
        </Flex>
        <span sx={{ textAlign: ['center', 'center', 'left'] }}>
          <a href={`https://maps.google.com?q=${SiteMeta.address}`}>{SiteMeta.address}</a>
        </span>
        <div sx={{ textAlign: ['center', 'center', 'right'], pr: [0, 0, 0, 5] }}>
          <a href={`tel:${SiteMeta.phone}`}>{SiteMeta.phone}</a><br />
          <a href={`mailto:${SiteMeta.phone}`}>{SiteMeta.email}</a>
        </div>
        <Media greaterThanOrEqual="sm">
          <Grid
            columns={2}
            gap={0}
            sx={{
              textAlign: 'right',
            }}
          >
            <Link to="/">Hem</Link>
            <Link to="/om-oss">Om oss</Link>
            <Link to="/kontakt">Kontakt</Link>
            <Link to="/karriar">Karriär</Link>
          </Grid>
        </Media>
      </Grid>
    </Flex>
  </Flex>
);

export default Footer;
